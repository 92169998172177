import { ApplicationController, useDebounce } from "stimulus-use";

// Connects to data-controller="auto-submit"
export default class extends ApplicationController {
  static targets = ["input"];
  static debounces = ["submit"];

  connect() {
    useDebounce(this, { wait: 500 });
    if (this.hasInputTarget) {
      const length = this.inputTarget.value.length;
      this.inputTarget.setSelectionRange(length, length);
    }
  }

  submit() {
    this.element.requestSubmit();
  }

  reset() {
    if (this.hasInputTarget) {
      this.inputTarget.value = "";
    }
    this.submit();
  }
}
