import { Application } from "@hotwired/stimulus"

const application = Application.start()

const context = require.context("./../../../app/views/components/", true, /controller.js$/)
context.keys().forEach((path) => {
  const mod = context(path)
  const identifier = path
    .match(/(.+)\/controller.js$/)[1]
    .replace("./", "")
    .replaceAll("/", "--")
    .replaceAll("_", "-")

  application.register(identifier, mod.default)
})

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
