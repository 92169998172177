import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="conditional-form"
export default class extends Controller {
  static values = { follow: Array }
  static targets = [ "field" ]

  showFields(event) {
    if (event.currentTarget.value === "") {
      this.fieldTargets.forEach((field) => {
        field.classList.add("hidden")
      })
    } else {
      this.fieldTargets.forEach((field) => {
        field.classList.remove("hidden")
      })
    }
  }

  displayFields(event) {
    const value = event.currentTarget.value
    if (this.followValue.includes(value)) {
      this.#removeAttributes()
      this.fieldTargets.forEach((field) => {
        field.setAttribute(`data-${value}`, true)
      })
    } else {
      this.#removeAttributes()
    }
  }

  #removeAttributes() {
    this.fieldTargets.forEach((field) => {
      this.followValue.forEach((follow) => {
        field.removeAttribute(`data-${follow}`)
      })
    })
  }
}
