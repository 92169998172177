// TODO: use turbo and other stimulus controller instead of this one
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="profile"
export default class extends Controller {
  static targets = [
    "button", "section", "form", "input", "updateButton", "currentData", "original",
    "showAccess", "accessForm", "currentAccesses", "accessesList"
  ]

  scroll(event) {
    this.buttonTargets.forEach((button) => {
      if (button === event.currentTarget) {
        button.classList.remove("text-gray-700")
        button.classList.remove("hover:text-cyan-600")
        button.classList.add("text-cyan-600")
        button.querySelector("svg").classList.remove("text-gray-400")
        button.querySelector("svg").classList.add("text-cyan-600")
      } else {
        button.classList.add("text-gray-700")
        button.classList.add("hover:text-cyan-600")
        button.classList.remove("text-cyan-600")
        button.querySelector("svg").classList.add("text-gray-400")
        button.querySelector("svg").classList.remove("text-cyan-600")
      }
    })
    this.sectionTargets.forEach((section) => {
      if (section.getAttribute("data-section") === event.currentTarget.getAttribute("data-section")) {
        section.scrollIntoView({ behavior: "smooth", block: "start"})
      }
    })
  }

  showForm(event) {
    const button = event.currentTarget
    const index = button.getAttribute("data-index")
    if (button.innerText === "Modifier") {
      button.innerText = "Annuler"
      button.classList.remove("text-cyan-600")
      button.classList.remove("hover:text-cyan-500")
      button.classList.add("text-red-500")
      button.classList.add("hover:text-red-600")
      this.formTargets[index].classList.remove("hidden")
      this.inputTargets[index].focus()
      this.inputTargets[index].select()
      this.currentDataTargets[index].classList.add("hidden")
    } else {
      button.innerText = "Modifier"
      button.classList.remove("text-red-500")
      button.classList.remove("hover:text-red-600")
      button.classList.add("text-cyan-600")
      button.classList.add("hover:text-cyan-500")
      this.currentDataTargets[index].classList.remove("hidden")
      this.formTargets[index].classList.add("hidden")
    }
  }

  togglePasswordForm(event) {
    const index = event.currentTarget.getAttribute("data-index")
    const spans = event.currentTarget.querySelectorAll("span")
    const secondSpan = spans[1]
    this.formTargets[index].classList.toggle("hidden")
    secondSpan.classList.toggle("translate-x-0")
    secondSpan.classList.toggle("translate-x-5")
    event.currentTarget.classList.toggle("bg-gray-200")
    event.currentTarget.classList.toggle("bg-cyan-600")
    if (!this.formTargets[index].classList.contains("hidden")) {
      this.inputTargets[index].focus()
      this.inputTargets[index].select()
    }
  }

  updateForm(event) {
    event.preventDefault()
    const index = event.currentTarget.getAttribute("data-index")
    let notice = ""
    if (index === "0") {
      notice = this.notice("Email modifié")
    } else if (index === "1") {
      notice = this.notice("Adresse modifiée")
    } else if (index === "2") {
      notice = this.notice("Mot de passe modifié")
    }
    fetch(this.formTargets[index].action, {
      method: 'PATCH',
      headers: {
        "Accept" : "text/html",
        "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content
      },
      body: new FormData(this.formTargets[index])
    })
    .then(response => response.json())
    .then((data) => {
      if (data.status === "success") {
        this.originalTargets[index].outerHTML = data.partial
        document.body.insertAdjacentHTML("beforeend", notice)
      } else {
        document.body.insertAdjacentHTML("beforeend", this.alert(data.partial))
      }
    })
  }

  notice(message) {
    const notice =
    `<div data-controller="alert" data-alert-target="alert" data-notification="notice" class="fixed hidden bottom-5 w-fit rounded-md bg-green-50 p-4 z-10">
    <div class="flex gap-6"><div class="flex-shrink-0"><svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
    </svg></div><div class="ml-3"><p class="text-sm font-medium text-green-800">${message}</p></div><div class="ml-auto pl-3"><div class="-mx-1.5 -my-1.5">
    <button type="button" data-action="click->alert#closeAlert" class="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50">
    <span class="sr-only">Dismiss</span><svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
    </svg></button></div></div></div></div>`
    return notice
  }

  alert(message) {
    const alert =
    `<div data-controller="alert" data-alert-target="alert" data-notification="alert" class="fixed hidden bottom-5 w-fit rounded-md bg-yellow-50 p-4 z-10">
    <div class="flex gap-6"><div class="flex-shrink-0"><svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
    </svg></div><div class="ml-3"><p class="text-sm font-medium text-yellow-800">${message}</p></div><div class="ml-auto pl-3"><div class="-mx-1.5 -my-1.5">
    <button type="button" data-action="click->alert#closeAlert" class="inline-flex rounded-md bg-yellow-50 p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50">
    <span class="sr-only">Dismiss</span><svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
    </svg></button></div></div></div></div>`
    return alert
  }

  showAccessForm(event) {
    this.accessFormTarget.classList.toggle("hidden")
    event.currentTarget.classList.toggle("text-red-500")
    event.currentTarget.classList.toggle("hover:text-red-600")
    event.currentTarget.classList.toggle("text-cyan-600")
    event.currentTarget.classList.toggle("hover:text-cyan-500")
    if (event.currentTarget.innerHTML === "Annuler") {
      event.currentTarget.innerHTML = `<span aria-hidden="true">+</span> Ajouter une autorisation`
      this.accessFormTarget.reset()
    } else {
      event.currentTarget.innerHTML = "Annuler"
      this.accessFormTarget.querySelector("select").focus()
    }
  }

  submitAccess(event) {
    event.preventDefault()
    const notice = this.notice("Autorisation ajoutée")
    fetch(this.accessFormTarget.action, {
      method: 'POST',
      headers: {
        "Accept" : "text/html",
        "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content
      },
      body: new FormData(this.accessFormTarget)
    })
    .then(response => response.text())
    .then((data) => {
      this.currentAccessesTarget.outerHTML = data
    })
    document.body.insertAdjacentHTML("beforeend", notice)
  }

  removeAccess(event) {
    event.preventDefault()
    const notice = this.notice("Autorisation supprimée")
    fetch(event.currentTarget.href, {
      method: 'DELETE',
      headers: {
        "Accept" : "text/html",
        "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content
      }
    })
    event.currentTarget.closest('li').remove()
    document.body.insertAdjacentHTML("beforeend", notice)
  }
}
