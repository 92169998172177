import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="disable-fields"
export default class extends Controller {
  static targets = ["field", "follower", "errorInput"];

  disableOrEnableFollower(event) {
    const value = event.currentTarget.value;
    this.followerTargets.forEach((follower) => {
      const enableOptions = JSON.parse(follower.dataset.enableOptions);
      if (enableOptions.includes(value)) {
        follower.disabled = false;
      } else {
        follower.disabled = true;
      }
    });
    if (this.hasErrorInputTarget) {
      this.errorInputTarget.value = value;
    }
  }

  submit(event) {
    event.preventDefault();
    if (this.element.checkValidity()) {
      this.fieldTargets.forEach((field) => {
        const disableOptions = JSON.parse(field.dataset.disableOptions);
        if (disableOptions.includes(field.value)) {
          field.disabled = true;
        }
      });
    }
    this.element.requestSubmit();
  }
}
