import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="change-dom"
export default class extends Controller {
  static targets = [ "element", "input", "follower" ]
  static values = { data: String, attribute: String }

  changeData(event) {
    const currentState = (event.currentTarget.getAttribute(`data-${this.dataValue}`) === "true")
    const targets = Array.from(this.element.querySelectorAll(`[data-${this.dataValue}]`))
    targets.forEach((target) => {
      target.setAttribute(`data-${this.dataValue}`, !currentState)
    })
    this.#focusInput()
  }

  changeAttribute() {
    this.elementTargets.forEach((element) => {
      element.setAttribute(this.attributeValue, true)
    })
    this.#focusInput()
  }

  changeFollower(event) {
    const currentState = event.currentTarget[this.attributeValue]
    const closestFollower = event.currentTarget.closest(`[data-change-dom-target]`) || event.currentTarget.nextElementSibling
    this.followerTargets.forEach((follower) => {
      if (follower.isSameNode(closestFollower)) {
        follower.setAttribute(`data-${this.attributeValue}`, currentState)
      } else {
        follower.removeAttribute(`data-${this.attributeValue}`)
      }
    })
  }

  #focusInput() {
    if (this.hasInputTarget) {
      this.inputTarget.focus()
      this.inputTarget.select()
    }
  }
}
