import { Controller } from "@hotwired/stimulus";
import { useResize, useWindowResize } from "stimulus-use";

// Connects to data-controller="hide-overflow"
export default class extends Controller {
  static targets = ["badge"];

  connect() {
    this.toolTip = this.element.querySelector("span");
    useResize(this);
    useWindowResize(this);
    this.#hideOverflow();
  }

  resize() {
    this.#hideOverflow();
  }

  windowResize() {
    this.#hideOverflow();
  }

  #hideOverflow() {
    const elementRect = this.element.getBoundingClientRect();
    const toolTipRect = this.toolTip.getBoundingClientRect();
    const gapColumn = parseFloat(getComputedStyle(this.element).columnGap) * 2;
    this.badgeTargets.forEach((badge) => {
      badge.classList.remove("hidden");
    });
    let isAtLeastOneBadgeHidden = false;
    for (let i = 0; i < this.badgeTargets.length; i++) {
      const badge = this.badgeTargets[i];
      const badgeRect = badge.getBoundingClientRect();
      const isOutsideParent =
        badgeRect.right > elementRect.right - toolTipRect.width - gapColumn;
      if (isOutsideParent) {
        badge.classList.add("hidden");
        isAtLeastOneBadgeHidden = true;
        const otherBadges = this.badgeTargets.slice(
          i,
          this.badgeTargets.length,
        );
        otherBadges.forEach((badge) => {
          badge.classList.add("hidden");
        });
        break;
      }
    }
    if (isAtLeastOneBadgeHidden) {
      this.toolTip.classList.remove("invisible");
    }
  }
}
