import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="nested-form"
export default class extends Controller {
  static targets = ["template", "container", "button"];
  static values = { rejected: String };

  duplicateTemplate() {
    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime().toString(),
    );
    this.containerTarget.insertAdjacentHTML("beforeend", content);
  }

  showContainer(event) {
    if (this.rejectedValue) {
      const value = event.currentTarget.value;
      if (this.rejectedValue.split(" ").includes(value)) {
        this.containerTarget.classList.add("hidden");
        return;
      }
    }
    this.containerTarget.classList.remove("hidden");
    this.buttonTarget.classList.remove("hidden");
  }

  removeForm(event) {
    const form = event.currentTarget.closest('div[id="object-form"]');
    form.remove();
  }
}
