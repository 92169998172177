import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-to"
export default class extends Controller {
  static targets = ["section"]

  scroll(event) {
    const eventSection = event.currentTarget.dataset.section
    const section = this.sectionTargets.find((section) => section.dataset.section === eventSection)
    section.scrollIntoView({ behavior: "smooth", block: "start" })
  }
}
