// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import {Turbo} from "@hotwired/turbo-rails"
import "chartkick/chart.js"
import "chartkick/highcharts"

Turbo.StreamActions.redirect = function() {
  Turbo.visit(this.target)
}
