import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="combobox"
export default class extends Controller {
  static targets = ["template", "select", "container"]

  addCheckbox(event) {
    const value = event.currentTarget.value
    if (value === "") {
      return
    }
    const selectedIndex = event.currentTarget.selectedIndex
    const text = event.currentTarget.options[selectedIndex].label
    const order = event.currentTarget.options[selectedIndex].dataset.order
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    this.containerTarget.insertAdjacentHTML("beforeend", content)
    const field = this.containerTarget.lastElementChild
    field.querySelector("input").value = value
    field.querySelector("input").dataset.order = order
    field.querySelector("label").innerText = text
    const option = event.currentTarget.querySelector(`option[value="${value}"]`)
    option.remove()
  }

  removeCheckbox(event) {
    const value = event.currentTarget.value
    const text = event.currentTarget.nextElementSibling.innerText
    const order = parseInt(event.currentTarget.dataset.order)
    const option = document.createElement("option")
    option.value = value
    option.innerText = text
    option.dataset.order = order
    this.selectTarget.insertBefore(option, this.selectTarget.childNodes[order + 1])
    event.currentTarget.parentNode.remove()
  }
}
