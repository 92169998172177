import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-infos"
export default class extends Controller {
  static targets = [ "text" ]

  showInfos(event) {
    const data = event.currentTarget.dataset.input
    const options = Array.from(this.textTarget.querySelectorAll("p"))
    options.forEach((option) => {
      if (option.dataset.input === data) {
        option.classList.remove("hidden")
      } else {
        option.classList.add("hidden")
      }
    })
  }
}
