import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="autocomplete-address"
export default class extends Controller {
  static targets = [
    "input",
    "address",
    "postCode",
    "city",
    "inseeCode",
    "researchList",
    "cityId",
  ];

  connect() {
    const inputs = Array.from(
      this.element.querySelectorAll("input, select"),
    ).filter((input) => input !== this.addressTarget);
    inputs.forEach((input) => {
      input.addEventListener("focus", () => {
        this.#hideList();
      });
    });
    this.currentSearchIndex = 0;
  }

  closeOnEscape(event) {
    if (event.code === "Escape") {
      this.#hideList();
    }
  }

  closeOnClick(event) {
    if (event && this.element.contains(event.target)) {
      return;
    }
    this.#hideList();
  }

  research(event) {
    if (event.code === "Enter") {
      return;
    } else if (["ArrowUp", "ArrowDown"].includes(event.code)) {
      event.preventDefault();
      this.#navigateList(event);
    } else {
      this.#callApi(event);
    }
  }

  submitResearch(event) {
    this.#submit(event.currentTarget);
  }

  submitViaEnter(event) {
    if (event.code === "Enter") {
      event.preventDefault();
      this.#submit(
        this.#setOptions().find(
          (option) => option.getAttribute("data-selected") === "true",
        ),
      );
    }
  }

  #hideList() {
    this.currentSearchIndex = 0;
    this.#removeSelected();
    this.researchListTarget.classList.add("hidden");
  }

  #setOptions() {
    const options = Array.from(this.researchListTarget.querySelectorAll("li"));
    return options;
  }

  #removeSelected() {
    this.#setOptions().forEach((option) => {
      option.removeAttribute("data-selected");
    });
  }

  #navigateList(event) {
    this.currentSearchIndex =
      event.code === "ArrowDown"
        ? this.currentSearchIndex + 1
        : this.currentSearchIndex - 1;
    if (this.currentSearchIndex - 1 < 0) {
      this.currentSearchIndex = this.#setOptions().length;
    } else if (this.currentSearchIndex - 1 === this.#setOptions().length) {
      this.currentSearchIndex = 1;
    }
    this.#removeSelected();
    this.#setOptions()[this.currentSearchIndex - 1].dataset.selected = true;
  }

  #callApi(event) {
    const address = event.currentTarget.value;
    if (address.length > 3) {
      this.researchListTarget.classList.remove("hidden");
      this.researchListTarget.innerHTML = "";
      fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(address)}&limit=5`,
      )
        .then((response) => response.json())
        .then((data) => {
          data.features.forEach((data) => {
            const li = `<li class="px-3 py-2 w-full data-[selected=true]:bg-cyan-600 data-[selected=true]:text-white hover:bg-cyan-600 hover:text-white" data-post-code="${data.properties.postcode}" data-address="${data.properties.name}"
            data-city="${data.properties.city}" data-insee-code="${data.properties.citycode}" data-action="click->autocomplete-address#submitResearch">${data.properties.label}</li>`;
            this.researchListTarget.insertAdjacentHTML("afterbegin", li);
          });
        });
    }
  }

  #submit(target) {
    this.addressTarget.value = target.getAttribute("data-address");
    this.postCodeTarget.value = target.getAttribute("data-post-code");
    this.cityTarget.value = target.getAttribute("data-city");
    if (this.hasCityIdTarget) {
      this.cityIdTarget.classList.add("hidden");
      this.cityIdTarget.disabled = true;
      this.cityTarget.classList.remove("hidden");
      this.cityTarget.disabled = false;
    }
    if (this.hasInputTarget) {
      this.inputTarget.value = `${this.addressTarget.value} ${this.postCodeTarget.value} ${this.cityTarget.value}`;
    }
    if (this.hasInseeCodeTarget) {
      this.inseeCodeTarget.value = target.getAttribute("data-insee-code");
    }
    this.#hideList();
  }
}
