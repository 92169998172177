import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "link" ]

  hover(event) {
    this.linkTargets.forEach((link) => {
      if (link === event.currentTarget) {
        link.dataset.selected = true
      } else {
        link.dataset.selected = false
      }
    })
  }

  hoverOut() {
    this.linkTargets.forEach((link) => {
      if (link.dataset.active === "true") {
        link.dataset.selected = true
      } else {
        link.dataset.selected = false
      }
    })
  }
}
