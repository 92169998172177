import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.main = this.element.parentElement.nextElementSibling
    this.main.classList.add("blur-sm")
    this.main.querySelectorAll("a").forEach((link) => {
      link.style.pointerEvents = "none"
      link.onclick = function(event) {
        event.preventDefault()
      };
    })
    this.element.parentElement.dataset.complete = "true"
    this.hideModalMethod = false
  }

  cancelModal(event) {
    event.preventDefault()
    this.#hideModal()
  }

  closeOnEscape(event) {
    if (event.code === "Escape") {
      this.#hideModal()
    }
  }

  closeOnClick(event) {
    if (event && this.element.contains(event.target)) {
      return
    }
    this.#hideModal()
  }

  #hideModal() {
    this.hideModalMethod = true
    this.element.parentElement.removeAttribute("data-complete")
    setTimeout(() => {
      this.main.classList.remove("blur-sm")
      this.main.querySelectorAll("a").forEach((link) => {
        link.style.pointerEvents = ""
        link.onclick = null
      })
      this.element.parentElement.removeAttribute("src")
      this.element.remove()
    }, 300)
  }
}
